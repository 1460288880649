// i18n
import 'src/locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// redux
import ReduxProvider from 'src/redux/redux-provider';
// routes
import { FormsRouter, AdminRouter } from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'src/auth/context/jwt';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/auth0';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/amplify';
// import { AuthProvider, AuthConsumer } from 'src/auth/context/firebase';

// ----------------------------------------------------------------------


// ////////////////////////////////////////////
//
//
// ADMIN
//
//
// ////////////////////////////////////////////

// export default function App() {
//   useScrollToTop();
//   return (
//     <AuthProvider>
//       <ReduxProvider>
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//           <SettingsProvider
//             defaultSettings={{
//               themeMode: 'light', // 'light' | 'dark'
//               themeDirection: 'ltr', //  'rtl' | 'ltr'
//               themeContrast: 'bold', // 'default' | 'bold'
//               themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
//               themeColorPresets: 'braido', // 'braido', 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
//               themeStretch: true, // true or false
//             }}
//           >
//             <ThemeProvider>
//               <MotionLazy>
//                 <SnackbarProvider>
//                   <SettingsDrawer />
//                   <ProgressBar />
//                   <AuthConsumer>
//                     <AdminRouter />
//                   </AuthConsumer>
//                 </SnackbarProvider>
//               </MotionLazy>
//             </ThemeProvider>
//           </SettingsProvider>
//         </LocalizationProvider>
//       </ReduxProvider>
//     </AuthProvider>
//   );
// }


// ////////////////////////////////////////////
//
//
// FORMS
//
//
// ////////////////////////////////////////////

export default function App() {
  useScrollToTop();
  return (
    <ReduxProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'bold', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'braido', // 'braido', 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: true, // true or false
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <FormsRouter />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </ReduxProvider>
  );
}
