export const working_conventions = [
  'Registrado em Empresa Privada',
  'Autônomo Informal',
  'MEI',
  'Empresário',
  'Aposentado',
  'Pensionista',
  'Servidor Público',
  'Militar',
  'Não Exerce Atividade Remunerada',
];

export const working_proofs = [
  'Renda Formal - Assalariado',
  'Renda Formal - Aposentado',
  'Renda Formal - Empresário',
  'Renda Formal - Funcioário Público',
  'Renda Mista - Formal mas com Complemento de Renda',
  'Renda Informal - Autônomo',
  'Renda Informal - Sem Registro',
];
